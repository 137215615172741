import Vue from 'vue';
import { $i18n } from '@/util/api';
import loginComponent from './login.vue';

// 返回一个 扩展实例构造器
const LoginConstructor = Vue.extend(loginComponent);

let instance: any;
let seed = 1;

interface ILogin {
  dialogVisible: boolean;
  [k: string]: any;
}

const Login = function (options?: ILogin) {
  if (Vue.prototype.$isServer) return;

  const id = 'login_' + seed++;

  instance = new LoginConstructor({
    data: options,
    methods: {
      t: (...args: any) => {
        return $i18n.t.apply($i18n, args);
      },
    },
  });
  instance.id = id;

  instance.$mount();
  document.body.appendChild(instance.$el);
  return instance;
};

Login.show = function () {
  // console.log('37-37,打开登录', instance);
  // 如果登录登录组件已经存在, 则不再打开
  if (instance && instance.dialogVisible) {
    // document.body.removeChild(instance.$el); // 删除重复打开的登录窗口(但未删除遮罩层 class="v-modal")
    return false;
  }
  return Login({
    dialogVisible: true,
  });
};

Login.hide = function () {
  // console.log('47-47关闭登录', instance);
  if (instance && instance.dialogVisible) {
    instance.dialogVisible = false;
    document.body.removeChild(instance.$el);
    // let modalEl: any = document.querySelector('.v-modal');
    // 检查body中是否有遮罩层，如果有 则删除遮罩层
    // if (modalEl) {
    //   document.body.removeChild(modalEl);
    // }
  } else {
    const search = location.search;
    if (search) {
      const uri = new URLSearchParams(search);
      if (uri.get('redirect')) {
        location.href = uri.get('redirect') || '/';
      }
    }
  }
};

export default Login;
